@import "./consts.scss";
@import "./admin.scss";

#super-admin-container {
    &.superadmin-schools {
        padding-bottom: 1%;
        height: fit-content;

        #above-table {
            width: 90%;
            display: flex;
            margin-top: 2%;
            justify-content: space-between;

            .title {
                text-align: right;
                width: 90%;
                margin: auto 0;
            }

            #add-school {
                background: $orangeColor;
                color: white;
                font-weight: bold;
                height: fit-content;
                margin: auto 0;
                padding: 1%;
                border-radius: 5%;
                border: none;
            }
        }

        #schools-table {
            margin: 1% auto;
            width: 90%;
            border: 1px solid #d5d5db;
            box-shadow: 0px 3px 6px #00000029;

            * {
                user-select: text !important;
                -webkit-user-select: text !important;
                -webkit-user-callout: text !important;
                -webkit-touch-callout: text !important;
            }

            th {
                background-color: $admin-light-grey;
                font-size: x-large;
            }

            tr {
                height: 8vh;
            }

            tr:nth-child(even) {
                // background: rgba(231, 228, 228, 0.40);
                background: $admin-light-grey;
            }

            tr:nth-child(odd) {
                background: #fff;
            }

            #edit-school {
                background: transparent;
                border: none;
                color: #666666;
            }
        }
    }

    #addEditSchool {
        min-width: 40%;
        height: 27%;
        display: flex;
        flex-direction: column;
        padding: 2%;
        justify-content: space-around;

        .school-info {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        .btn-disabled {
            color: $admin-grey !important;
            opacity: 0.7;
        }
    }
}