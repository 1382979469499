@function vh($quantity) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}
@function vw($quantity) {
  @return calc(var(--vw, 1vw) * #{$quantity});
}

#background-container-div {
    background: #5f5f5f;
    opacity: 0.5;
    width: vw(100);
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 11;
  }
  .background-fade-in {
    -webkit-animation: background-fade-in 0.5s linear;
    -moz-animation: background-fade-in 0.5s linear;
    -o-animation: background-fade-in 0.5s linear;
    animation: background-fade-in 0.5s linear;
  }
  .background-fade-out {
    -webkit-animation: background-fade-out 0.5s linear;
    -moz-animation: background-fade-out 0.5s linear;
    -o-animation: background-fade-out 0.5s linear;
    animation: background-fade-out 0.5s linear;
  }
  @-webkit-keyframes background-fade-out {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes background-fade-out {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes background-fade-out {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes background-fade-out {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes background-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-moz-keyframes background-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  @-o-keyframes background-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  @keyframes background-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  