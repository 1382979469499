@import "./consts.scss";

$bar-height: 10vh;

#super-admin-container #top-bar {
  width: 100vw;
  height: $bar-height;
  background-color: white;
  box-shadow: 0px -15px 20px 0px black;
  //   rgba(0, 0, 0, 0.51) 0px 5px 21px -7px
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5vw;

  #gp-text {
    font-family: "Heebo" !important;
    line-height: $bar-height;
    height: $bar-height;
    color: $orangeColor;
    font-weight: bold;
    font-size: 5vh;
  }
  #admin-name {
    font-family: "Heebo" !important;
    color: black;
    line-height: $bar-height;
    height: $bar-height;
    font-size: 3vh;
  }
}
