@import "../components/CSS/admin_consts.scss";

.admin-text-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .admin-text-input-label {
        color: $admin-medium-grey;
        font-weight: bold;
    }
    .admin-text-input-input {
        padding: 2px 10px;
        background-color: #f0f1f6;
        min-height: 40px;
        height: 100%;
        border-radius: 10px;
        color: $admin-darker-grey;
        border: none;
        outline: none;
        &:focus {
            @include input-focus-style();
        }
        &:disabled {
            background-color: #bbbbbb;
            opacity: .6;
            cursor: not-allowed;
        }
    }
    .admin-text-input-input--error {
        border: 1px solid $admin-error-border;
    }
    .admin-text-input-helperText {
        color: $admin-darker-grey;
        text-align: right; // added
        max-width: 170px; // when have helperText, might become really long //todo check on a bigger screen (how the input looks like, with a long helperText)
    }
}
