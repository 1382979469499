@import "./consts.scss";
@import "./admin.scss";

#super-admin-container {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  #functions-container {
    width: 90%;
    height: 50%;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
