@import "../components/CSS/consts.scss";
$default-color: #cecece;
// recommended to be the same color (looks nice) :
$bg-color: $default-color;
$border-color: $default-color;
$cancel-color: $default-color;
$cancel-border-color: $default-color;

#general-alert-container {
    color: white;

    margin: 0 auto;
    margin-bottom: 1rem;
    margin-left: 2rem;
    border: 1px solid transparent;
    border-radius: 0.2rem;
    padding: 0 10px;

    position: absolute;
    bottom: 0;
    left: 0;
    // right: 0;

    width: fit-content !important;
    padding: 4px 10px;
    // height: 2.5rem;
    // line-height: 2.5rem; //must be the same as the height
    @include heeboFontFamily;
}
.timeout-animation {
    animation: alert-msg-animation;
    animation-duration: 5s;
    opacity: 0;
}
.warning-color {
    background-color: rgb(219, 103, 103);
}
.default-color {
    background-color: rgba(0, 0, 0, 0.5);
}
.center {
    margin: 0 auto !important;
    margin-bottom: 1rem !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    // transform: translate(50%)!important;
}

@keyframes alert-msg-animation {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// #popup-alert-full-window {
//     //full window for background opacity
//     position: absolute;
//     height: 100vh;
//     width: 100vw;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 3;
//     background-color: #747477bf;

//     .popup-alert-container {
//         //white background (the popup container)
//         background-color: white;
//         width: 90vw;
//         max-width: 600px;
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         transform: translate(-50%, -50%);

//         min-height: 30vh;
//         max-height: 80vh;

//         display: flex;
//         flex-direction: column;
//         justify-content: space-evenly;

//         padding: 35px;
//         // border-radius: 10px; //! changed for good point admin

//         #popup-text {
//             color: black;
//             font-size: 1.5rem;
//             // @include heeboFontFamily;
//         }

//         .popup-buttons-container {
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: space-evenly;
//             button {
//                 // @include heeboFontFamily;
//                 border-radius: 10px;
//                 width: fit-content;
//                 max-width: 210px;
//                 padding: 10px 10px;
//                 cursor: pointer;
//                 h4 {
//                     font-size: 1.2rem;
//                     height: 100%;
//                     margin: 0;
//                 }
//             }
//             .popup-okay {
//                 background-color: $bg-color;
//                 color: white;
//                 border: 1px solid $border-color;
//             }

//             .popup-cancel {
//                 background-color: white;
//                 color: $cancel-color;
//                 border: 1px solid $cancel-border-color;
//             }
//         }
//     }
// }
