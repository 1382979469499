.link-w-icon {
    display: flex;
    justify-content: space-between;

    --horizontal_margin: 5px;

    &.margin--10 {
        --horizontal_margin: 10px;
    }

    svg {
        margin-left: var(--horizontal_margin);
        // font-size: 1rem;
        vertical-align: middle;
    }


    &:not(.only-margin-left) {
        margin-right: var(--horizontal_margin);
    }

    a {
        flex-grow: 1;
        text-align: right;
    }
}