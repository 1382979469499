@import "./consts.scss";

@font-face {
    font-family: "Assistant-Medium";
    // src: url("/files/Assistant-Medium.ttf");
    src: url("../../../public/files/Assistant-Medium.ttf")
}

#hello_first_page {
    font-family: "Assistant-Medium" !important;

    #image_cont {
        padding: 5vw;

        #welcomeLogo {
            width: 25vw;
        }
    }

    #video {
        width: 100%;
        min-height: 27vh;

        @media only screen and (max-width: 650px) {
            min-height: 15vh;
        }

        height: fit-content;
    }

    #content {
        margin-top: 1vh;

        #desc {
            color: $pinkColor;
            font-weight: bolder;
            line-height: 1.5;
            margin: 2rem 5px;
            font-size: 2.5rem;
        }

        #people_img {
            height: 30vh;
        }

        #schoolsEntery {
            margin: 3vh auto;
            color: #8a8888;
            font-weight: 500;
            font-size: 2.1rem;
        }

        span {
            display: flex;
            width: 70%;
            margin: auto;
            justify-content: space-around;

            .lang-btn {
                background-image: $pinkOrangeGradient;
                font-size: 1.6rem;
                color: white;
                padding: 4px 18px;
                border-radius: 35px;
                border: none;
                margin-bottom: 3vh;
            }
        }
    }

    #footer {
        width: 100%;
    }

    #down_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;

        .above {
            height: 10vh;
        }

        .below {
            height: 5vh;
        }

        a {
            color: $pinkColor;
        }
    }

    @media screen and (max-width: 650px) {

        #content {

            #desc,
            #schoolsEntery {
                font-size: 2rem !important;
            }

            span {
                .lang-btn {
                    font-size: 1.3rem;
                }
            }
        }

        #down_content {
            .below {
                height: 10vh;
            }
        }

        #image_cont #welcomeLogo {
            height: 20vw;
            width: unset;
        }
    }
}