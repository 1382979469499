* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  max-width: 100vw;
  /* overflow-x: hidden; */
  margin: 0;
  width: 100vw;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  overflow-x: hidden; */
  /* height:100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.transparent-btn {
  background-color: transparent;
  border: none;
}
